@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  scroll-behavior: smooth;
  font-family: Arial, 'Noto Sans TC', '文泉驛正黑', 'WenQuanYi Zen Hei', '儷黑 Pro', 'LiHei Pro', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
}

*:focus:not(:focus-visible),
*::before:focus:not(:focus-visible),
*::after:focus:not(:focus-visible) {
  outline: none;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  opacity: 0.9;
}

:focus-visible {
  outline: none;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
}

p {
  font-size: 1rem;
  line-height: 1rem;
  margin: 0px;
}

ul, li {
  margin: 0px;
  list-style-type: none;
}

input {
  outline: none;
  border: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover, .pointer {
  cursor: pointer;
}

.bg-01 {
  background: #faad91;
}

.bg-02 {
  background: #799ad0;
}

.bg-03 {
  background: #f7c262;
}

.bg-04 {
  background: #cab3e9;
}

.bg-05 {
  background: #a6d3fc;
}

.bg-06 {
  background: #f7cda3;
}

.bg-07 {
  background: #60c9c5;
}

.bg-08 {
  background: #faa3b6;
}

.bg-p1 {
  background: #f9ae18;
}

.color-p1 {
  color: #f9ae18;
}

.color-p2 {
  color: #19283e;
}

.color-p3 {
  color: #848c99;
}

.color-p4 {
  color: #788aa6;
}

.min-w-1200 {
  min-width: auto;
}

.containers, .containers-fluid, .containers-lg, .containers-md, .containers-sm, .containers-xl, .containers-xxl {
  /* --gutter-x: 16px;
  --gutter-y: 16px; */
  width: 100%;
  /* padding-right: calc(var(--gutter-x) * .5);
  padding-left: calc(var(--gutter-x) * .5); */
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containers, .containers-sm {
    max-width: 640px;
  }
  .min-w-1200 {
    min-width: 1200px;
  } 
}

@media (min-width: 768px) {
  .containers, .containers-md, .containers-sm {
    max-width: 800px;
  }
}

@media (min-width: 992px) {
  .containers, .containers-lg, .containers-md, .containers-sm {
    max-width: 1100px;
  }
}

@media (min-width: 1200px) {
  .containers, .containers-lg, .containers-md, .containers-sm, .containers-xl {
    max-width: 1340px;
  }
}

@media (min-width: 1400px) {
  .containers, .containers-lg, .containers-md, .containers-sm, .containers-xl, .containers-xxl {
    max-width: 1520px;
  }
}

.bg-point {
  background: url(./assets/img/bg-point.png) left 0 no-repeat;
  background-size: contain;
  width: fit-content;
  padding: 4px 4px 4px 14px;
}

.data-\[te-nav-active\]\:bg-p1[data-te-nav-active] {
  background: #f9ae18;
  border-radius: 8px;
}

/* @media(min-width:576px){
  .data-\[te-nav-active\]\:bg-p1[data-te-nav-active] {
  background: #fff;
  color: #000;
  }
} */

.hover\:bg-neutral-100:hover {
  border-radius: 8px;
}

.datepicker.datepicker-dropdown.dropdown {
  left: 0!important;
  right: 0;
  text-align: center;
}

.datepicker-picker.inline-block {
  box-shadow: 0 0 10px 5px #ffcab969;
}

.data-\[te-tab-active\]\:block[data-te-tab-active] {
  display: block;
}

@media (min-width: 576px) {
  .sm\:w-\[82px\] {
    width: 82px;
  }
}

@media (min-width: 576px) {
  .sm\:w-\[60px\] {
    width: 60px;
  }
}
